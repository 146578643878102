import React from "react"

import ProtectedRouteLayout from "../components/Layout/ProtectedRouteLayout"
import WashingConfirmLayout from "../components/Layout/WashingConfirmLayout"
const WASHING_DROP_OFF = "WASHING_DROP_OFF"

const dropOffConfirm = () => {
  return (
    <ProtectedRouteLayout>
      <WashingConfirmLayout type={WASHING_DROP_OFF} />
    </ProtectedRouteLayout>
  )
}

export default dropOffConfirm
